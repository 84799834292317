import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import createDOMForm from "rc-form/lib/createDOMForm";
import { TextArea, Toast, Result, Input } from "antd-mobile";

import * as qiniuApi from "../../apis/qiniuApi";
import * as assessApi from "../../apis/assessApi";
import dsBridge from "dsbridge";

import "./index.less";
import IMG_INFO from "./images/ic_assess_info.png";
import IMG_WAITING from "./images/ic_assess_waiting.svg";
import IMG_WARNING from "./images/ic_assess_warning.svg";

import UploadComp from "../../components/upload";
import TextInputComp from "../../components/textInput";
import ListSelector from "../../components/ListSelector";

const uniqueString = require('unique-string');
const FootAssessPage = (props) => {
  const [headers, setHeaders] = useState({});
  const [assess, setAssess] = useState({});
  const [assessData, setAssessData] = useState({});
  const [loadStatus, setLoadStatus] = useState("NONE");
  const [caseImages, setCaseImages] = useState([]);
  let { assessId } = useParams();
  const location = useLocation();

  const getTokenAndFetchData = async () => {
    const query = new URLSearchParams(location.search);
    if (query.get("vc") !== null && !(headers && headers["X-Mp-LoginToken"])) {
      const tokenInfo = {
        "X-Mp-LoginToken": query.get("vc"),
        "X-DeviceToken": query.get("vd"),
        "X-Mp-AppId": "wxd3025ff909e390bb",
        "X-Platform": "iOS",
        "X-App-Id": "HEALTH",
        "X-Version": "4.6.0",
        "X-Build": "20230401",
        "X-Channel": "AppStore",
      };

      setHeaders(tokenInfo);
      await fetchData(tokenInfo);
    } else if (headers && headers["X-Mp-LoginToken"]) {
      await fetchData(headers);
    } else {
      const params = {
        action: "getToken",
      };

      dsBridge.call(
        "egghealth.asyncHandler",
        JSON.stringify(params),
        async (data) => {
          if (data) {
            const tokenInfo = JSON.parse(data) || {};
            setHeaders(tokenInfo);
            await fetchData(tokenInfo);
          } else {
            setLoadStatus("FAILED");
          }
        }
      );
    }
  };

  const fetchData = async (tokenInfo) => {
    setLoadStatus("LOADING");
    var isSuccess = true;

    var res = await assessApi.getAssess(assessId, tokenInfo);
    if (res.errCode !== 0) {
      Toast.show({
        content: res.errMsg,
        icon: "fail",
      });
      isSuccess = false;
    } else {
      let tmpValues = res.submitData ? JSON.parse(res.submitData) : {};
      setAssessData(tmpValues);
      setCaseImages(tmpValues.caseImages || []);
      setAssess(res);
    }

    setLoadStatus(isSuccess ? "LOADED" : "FAILED");
  };

  useEffect(() => {
    // const assessId = props.match.params.assessId;
    getTokenAndFetchData();
    // return () => {
    //   if (assess.status !== "SUBMITTED") {
    //     let values = props.form.getFieldsValue([]);
    //     localStorage.setItem(`ASSESS_DATA_${assessId}`, JSON.stringify(values));
    //   }
    // }
  }, [assessId]);

  const onSubmit = (e) => {
    props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        var tmpValues = {
          ...values,
          hasWeiDuInfo: false,
          caseImages: (caseImages || []).filter(item => item.assetKey && item.assetKey.length > 0)
        };
        console.log('tmpValues', tmpValues);

        getTokenAndSubmitAssess({
          id: assessId,
          name: tmpValues.name || '脊柱侧弯默认用户',
          coverKey: (tmpValues.userFrontImage || {}).qiniuKey,
          submitData: JSON.stringify(tmpValues),
        });
      } else {
        Toast.show({
          content: "资料不完整，请填写完整后提交～",
          icon: "fail",
        });
      }
    });
  };

  const onSaveDraft = (e) => {
    e.preventDefault();
    var values = props.form.getFieldsValue();
    values = {
      ...values,
      hasWeiDuInfo: true,
      caseImages: (caseImages || []).filter(item => item.assetKey && item.assetKey.length > 0)
    };
    saveDraft(
      {
        id: assessId,
        name: values.name,
        coverKey: (values.userFrontImage || {}).qiniuKey,
        submitData: JSON.stringify(values),
      },
      headers
    );
  };

  const getTokenAndSubmitAssess = async (data) => {
    if (headers && headers["X-Mp-LoginToken"]) {
      console.log("hasToken");
      submitAssess(data, headers);
    } else {
      console.log("noToken");

      const params = {
        action: "getToken",
      };

      dsBridge.call(
        "egghealth.asyncHandler",
        JSON.stringify(params),
        async (tokenStr) => {
          if (tokenStr) {
            var tokenInfo = JSON.parse(tokenStr) || {};
            setHeaders(tokenInfo);
            submitAssess(data, tokenInfo);
          } else {
            Toast.show({
              content: "获取登录信息失败",
              icon: "fail",
            });
          }
        }
      );
    }
  };

  const submitAssess = async (data, tokenInfo) => {
    var res = await assessApi.submitAssess(data, tokenInfo);
    if (res.errCode !== 0) {
      Toast.show({
        content: res.errMsg,
        icon: "fail",
      });
      if (res.errCode === 4032) {
        setHeaders({});
      }
    } else {
      Toast.show({
        content: "资料提交成功，请耐心等待评估结果",
        icon: "success",
      });
      assess.status = "SUBMITTED";
      setAssess({
        ...assess,
        status: "SUBMITTED",
      });
    }
  };

  const saveDraft = async (data, tokenInfo) => {
    var res = await assessApi.saveAssess(data, tokenInfo);
    if (res.errCode !== 0) {
      Toast.show({
        content: res.errMsg,
        icon: "fail",
      });
      if (res.errCode === 4032) {
        setHeaders({});
      }
    } else {
      Toast.show({
        content: "资料保存成功",
        icon: "success",
      });
    }
  };

  const getPicToken = async (callback) => {
    var res = await qiniuApi.getPicToken(headers);
    if (res.errCode !== 0) {
      Toast.show({
        content: "获取图片上传码失败",
        icon: "fail",
      });
      callback({ token: "" });
    } else {
      callback(res);
    }
  };

  const getVideoToken = async (callback) => {
    var res = await qiniuApi.getVideoToken(headers);
    if (res.errCode !== 0) {
      Toast.show({
        content: "获取视频上传码失败",
        icon: "fail",
      });
      callback({ token: "" });
    } else {
      callback(res);
    }
  };

  const onRetry = () => {
    fetchData(headers);
  };

  const checkImageSize = (file) => {
    if (file.size / 1024 / 1024 > 20) {
      Toast.show({
        content: "图片太大，图片大小不能超过20MB",
        icon: "fail",
      });
      return false;
    }
    return true;
  };

  const checkVideoSize = (file) => {
    if (file.size / 1024 / 1024 > 300) {
      Toast.show({
        content: "视频太大，视频大小不能超过300MB",
        icon: "fail",
      });
      return false;
    }
    return true;
  };

  const handleChange = (field, val) => {
    setAssessData({
      ...assessData,
      [field]: val,
    });
  };

  const imgHost = "https://syh-img.shareyourhealth.cn/";
  const vidHost = "https://syh-video.shareyourhealth.cn/";
  var uploadVideoList = [
    {
      key: "postureVideo",
      title: "体态视频",
      demoUrl: vidHost + "lisY5LFnwOusc6LtcDYW8RPmq_Si.mp4_thumb.mp4",
      poster: imgHost + "lisY5LFnwOusc6LtcDYW8RPmq_Si.mp4_cover.jpg",
      name: "postureVideo",
    },
  ];

  const myImg = (src) => <img src={src} className="assess-result-img" alt="" />;

  const { getFieldError, getFieldDecorator, getFieldProps, getFieldValue } =
    props.form;
  var showAssessStatus =
    loadStatus === "LOADED" &&
    assess.status !== "PENDING" &&
    assess.status !== "REJECTED";
  var showWarning = loadStatus === "FAILED";
  var showLoading = loadStatus === "NONE" || loadStatus === "LOADING";
  var showForm = !showAssessStatus && !showWarning && !showLoading;
  var showSaveDraft =
    loadStatus === "LOADED" &&
    (assess.status === "PENDING" || assess.status === "REJECTED");

  const genderList = [
    {
      label: "女",
      value: "1",
    },
    {
      label: "男",
      value: "0",
    },
  ];

  return (
    <div>
      <div style={{ display: showWarning ? "block" : "none" }}>
        <Result
          img={myImg(IMG_WARNING)}
          title="数据加载失败"
          message={<div className="assess-result-message">请刷新页面重试</div>}
        />
        <div className="assess-result-button" onClick={onRetry}>
          刷新重试
        </div>
      </div>
      <div style={{ display: showLoading ? "block" : "none" }}>
        <div className="assess-loading-text">数据加载中...</div>
      </div>
      <div style={{ display: showAssessStatus ? "block" : "none" }}>
        <Result
          img={myImg(IMG_WAITING)}
          title="正在评估"
        />
        <div className="assess-result-message">请耐心等待评估结果</div>
      </div>
      <div style={{ display: showForm ? "block" : "none" }}>
        <div className="assess-section-tips">
          <div className="assess-service-info">
            <img
              src={IMG_INFO}
              alt=""
              className="assess-section-tips-icon"
            ></img>
            请仔细填写资料信息，如有疑问联系助手微信 {assess.serviceWechat}
          </div>
          <div
            className="assess-section-rejected-info"
            style={{ display: assess.status === "REJECTED" ? "block" : "none" }}
            dangerouslySetInnerHTML={{
              __html: (
                "资料不符合要求，请修改后再提交：\n" + assess.rejectReason
              ).replace(/\n/g, "<br />"),
            }}
          ></div>
        </div>
        <div className="assess-section-sep"></div>
        <div className="assess-section-title">填写基本资料</div>
        <div>
          <TextInputComp
            title="年龄"
            placeholder="请输入年龄"
            type="number"
            {...getFieldProps("age", {
              initialValue: assessData.age || "",
              rules: [
                {
                  type: "string",
                  required: true,
                  message: "请输入年龄",
                },
              ],
            })}
          ></TextInputComp>
          {(getFieldError("age") || []).join(", ") !== "" ? (
            <div className="assess-input-error">
              {(getFieldError("age") || []).join(", ")}
            </div>
          ) : null}
        </div>
        <div>
          <TextInputComp
            title="身高（厘米）"
            placeholder="请输入"
            type="number"
            {...getFieldProps("height", {
              initialValue: assessData.height || "",
              rules: [
                {
                  type: "string",
                  required: true,
                  message: "请输入身高",
                },
              ],
            })}
          ></TextInputComp>
          {(getFieldError("height") || []).join(", ") !== "" ? (
            <div className="assess-input-error">
              {(getFieldError("height") || []).join(", ")}
            </div>
          ) : null}
        </div>
        <div>
          <TextInputComp
            title="体重（公斤）"
            placeholder="请输入"
            type="number"
            {...getFieldProps("weight", {
              initialValue: assessData.weight || "",
              rules: [
                {
                  type: "string",
                  required: true,
                  message: "请输入体重",
                },
              ],
            })}
          ></TextInputComp>
          {(getFieldError("weight") || []).join(", ") !== "" ? (
            <div className="assess-input-error">
              {(getFieldError("weight") || []).join(", ")}
            </div>
          ) : null}
        </div>
        <div>
          <ListSelector
            title="性别"
            placeholder="请选择"
            data={genderList}
            {...getFieldProps("gender", {
              initialValue: assessData.gender || "1",
              rules: [
                {
                  type: "string",
                  required: true,
                  message: "请选择性别",
                },
              ],
            })}
          ></ListSelector>
          {(getFieldError("gender") || []).join(", ") !== "" ? (
            <div className="assess-input-error">
              {(getFieldError("gender") || []).join(", ")}
            </div>
          ) : null}
        </div>
        {getFieldValue("gender") === "1" && (
          <div>
            <TextInputComp
              title="初潮年龄"
              placeholder="请输入"
              type="number"
              {...getFieldProps("periodAge", {
                initialValue: assessData.periodAge || "",
                rules: [
                  {
                    type: "string",
                    message: "请输入初潮年龄",
                  },
                ],
              })}
            ></TextInputComp>
            {(getFieldError("periodAge") || []).join(", ") !== "" ? (
              <div className="assess-input-error">
                {(getFieldError("periodAge") || []).join(", ")}
              </div>
            ) : null}
          </div>
        )}

        <div>
          <TextInputComp
            title="身高变化（厘米）"
            placeholder="请输入"
            inputmode="numeric"
            {...getFieldProps("deltaHeight", {
              initialValue: assessData.deltaHeight || "",
              rules: [
                {
                  type: "string",
                  required: true,
                  message: "近一年身高增长",
                },
              ],
            })}
          ></TextInputComp>
          {(getFieldError("deltaHeight") || []).join(", ") !== "" ? (
            <div className="assess-input-error">
              {(getFieldError("deltaHeight") || []).join(", ")}
            </div>
          ) : null}
        </div>

        <div className="assess-section-sep"></div>
        <div className="assess-section-title">补充说明</div>
        <div className="assess-section-desc">
          您可以对孩子的矫正诉求、问题及目标等，进行补充说明
        </div>
        <div className="assess-section-line"></div>
        <div>
          <TextArea
            placeholder="请输入"
            style={{ margin: "16px", width: "calc(100% - 32px)" }}
            rows={8}
            value={assessData.userRemark}
            onChange={(val) => handleChange("userRemark", val)}
            {...getFieldProps("userRemark", {
              initialValue: assessData.userRemark,
              rules: [
                {
                  type: "string",
                  message: "请输入补充说明",
                },
              ],
            })}
          ></TextArea>
          {(getFieldError("userRemark") || []).join(", ") !== "" ? (
            <div className="assess-input-error">
              {(getFieldError("userRemark") || []).join(", ")}
            </div>
          ) : null}
        </div>
        <div style={{ color: "#fff", height: "16px" }}></div>
        <div style={{ color: "#fff", height: "16px" }}></div>

        <div className="assess-section-sep"></div>
        <div className="assess-section-title">上传影像报告</div>
        <div className="assess-section-desc">
          为保证评估结果准确性，请务必按照示范要求上传照片。
        </div>
        <div className="assess-image-list">
          <div className="assess-image-item" style={{ height: '180px' }}>
            <img src="https://syh-img.shareyourhealth.cn/egghealthma/ic_x_front.jpg" alt=""></img>
          </div>
          <div className="assess-image-item" style={{ height: '180px' }}>
            <img src="https://syh-img.shareyourhealth.cn/egghealthma/ic_x_side.jpg" alt=""></img>
          </div>
          <div className="assess-image-item" style={{ height: '180px' }}>
            <img src="https://syh-img.shareyourhealth.cn/egghealthma/ic_x_report.jpg" alt=""></img>
          </div>
        </div>
        <div style={{ color: "#fff", height: "16px" }}></div>
        <div className="assess-image-list">
          <div className="assess-upload-item">
            {getFieldDecorator("xRayFrontImage", {
              initialValue: assessData.xRayFrontImage,
              rules: [
                {
                  validator: (rule, value) => {
                    const errors = [];
                    if (value && value.qiniuKey) {
                      return errors;
                    }
                    errors.push("请上传正面X光片");
                    return errors;
                  },
                },
              ],
            })(
              <UploadComp
                accept="image/*"
                title="上传正面X光片"
                beforeUpload={checkImageSize}
                getUploadToken={getPicToken}
              ></UploadComp>
            )}
          </div>
          <div className="assess-upload-item">
            <UploadComp
              accept="image/*"
              title="上传侧面X光片"
              beforeUpload={checkImageSize}
              getUploadToken={getPicToken}
              {...getFieldProps("xRaySideImage", {
                initialValue: assessData.xRaySideImage,
                rules: [
                  {
                    validator: (rule, value) => {
                      const errors = [];
                      if (value && value.qiniuKey) {
                        return errors;
                      }
                      errors.push("请上传侧面X光片");
                      return errors;
                    },
                  },
                ],
              })}
            ></UploadComp>
          </div>
          <div className="assess-upload-item">
            <UploadComp
              accept="image/*"
              title="上传诊断报告"
              beforeUpload={checkImageSize}
              getUploadToken={getPicToken}
              {...getFieldProps("clinicReportImage", {
                initialValue: assessData.clinicReportImage,
                rules: [
                  {
                    validator: (rule, value) => {
                      const errors = [];
                      if (value && value.qiniuKey) {
                        return errors;
                      }
                      errors.push("请上传诊断报告");
                      return errors;
                    },
                  },
                ],
              })}
            ></UploadComp>
          </div>
        </div>
        <div style={{ color: "#fff", height: "24px" }}></div>
        <div className="assess-input-error">
          {(getFieldError("xRayFrontImage") || []).join(", ")}
        </div>
        <div className="assess-input-error">
          {(getFieldError("xRaySideImage") || []).join(", ")}
        </div>
        <div className="assess-input-error">
          {(getFieldError("clinicReportImage") || []).join(", ")}
        </div>
        <div style={{ color: "#fff", height: "24px" }}></div>

        <div className="assess-section-sep"></div>
        <div className="assess-section-title">上传体态视频</div>
        <div className="assess-section-subtitle">
          <div>视频提交后无法修改，请按以下要求拍摄：</div>
          <div>
            1.被拍人员需在整个画面中间，长发需要盘起不佩戴任何首饰。穿运动胸衣＋紧身运动裤，或贴身内衣裤，光脚并拢自然站立。依次拍摄正面、背面、侧面，每一面停留3秒左右。
          </div>
          <div>
            2.请选择白色或纯色墙壁作为背景，颜色与衣服反差明显，切记四周不要有较多杂物干扰。人站在距离墙壁50cm处，拍摄手机距离人2米-3米之间，竖拍。
          </div>
          <div>3.介意者可戴口罩或遮挡面部，但需露出耳根、颈椎。</div>
        </div>
        <div className="assess-section-desc"></div>
        {uploadVideoList.map((uVideo) => (
          <div className="assess-upload-video-wrapper" key={uVideo.key}>
            <div className="assess-upload-video-input-wrapper">
              <div className="assess-upload-video-input-demo">
                <video
                  controls
                  controlsList={"nodownload nofullscreen"}
                  disablePictureInPicture
                  playsInline
                  x5-playsinline="true"
                  webkit-playsinline="true"
                  src={uVideo.demoUrl}
                  poster={uVideo.poster}
                ></video>
              </div>
            </div>
            <div className="assess-upload-video-input-wrapper">
              <div className="assess-upload-video-input-item">
                <UploadComp
                  accept="video/*"
                  title="上传视频"
                  beforeUpload={checkVideoSize}
                  getUploadToken={getVideoToken}
                  {...getFieldProps(uVideo.name, {
                    initialValue: assessData[uVideo.name],
                    rules: [
                      {
                        validator: (rule, value) => {
                          const errors = [];
                          if (value && value.qiniuKey) {
                            return errors;
                          }
                          errors.push("请上传视频");
                          return errors;
                        },
                      },
                    ],
                  })}
                ></UploadComp>
              </div>
            </div>
            {(getFieldError(uVideo.name) || []).join(", ") !== "" ? (
              <div
                className="assess-input-error"
                style={{ margin: "14px 14px 0px 14px" }}
              >
                {(getFieldError(uVideo.name) || []).join(", ")}
              </div>
            ) : null}
          </div>
        ))}

        <div className="assess-section-sep"></div>
        <div className="assess-section-title">运动习惯</div>
        <div className="assess-section-desc">
          请填写日常运动习惯及频次，辅助判断脊柱侧弯的原因，评估报告会结合您的运动习惯出具。
        </div>
        <div className="assess-section-line"></div>
        <TextArea
          placeholder="填写运动习惯及运动频次"
          rows={8}
          style={{ margin: "16px", width: "calc(100% - 32px)" }}
          value={assessData.exerciseHabit}
          onChange={(val) => handleChange("exerciseHabit", val)}
          {...getFieldProps("exerciseHabit", {
            initialValue: assessData.exerciseHabit,
            rules: [
              {
                type: "string",
                message: "请输入",
              },
            ],
          })}
        ></TextArea>

        <div className="assess-section-sep"></div>
        <div className="assess-section-title">疾病及治疗史</div>
        <div className="assess-section-desc">
          描述您存在的慢性病，是否做过手术，及近1年发生的疾病和治疗史。
        </div>
        <div className="assess-section-line"></div>
        <TextArea
          placeholder="填写疾病及治疗史"
          rows={8}
          style={{ margin: "16px", width: "calc(100% - 32px)" }}
          value={assessData.userComplaint}
          onChange={(val) => handleChange("userComplaint", val)}
          {...getFieldProps("userComplaint", {
            initialValue: assessData.userComplaint,
            rules: [
              {
                type: "string",
                message: "请输入",
              },
            ],
          })}
        ></TextArea>

        <div>
          <div className="assess-image-list-v2">
            {(caseImages || []).map((item, index) => (
              <div
                className="assess-image-list-v2-item"
                key={`caseImages_${index}`}
              >
                <UploadComp
                  accept="image/*"
                  title="上传病例照片"
                  beforeUpload={checkImageSize}
                  getUploadToken={getPicToken}
                  value={item}
                  onChange={(val) => {
                    caseImages.splice(index, 1, val);
                    setCaseImages([...caseImages]);
                  }}
                ></UploadComp>
              </div>
            ))}
            {(caseImages || []).length < 9 && (
              <div className="assess-image-list-v2-item" key={uniqueString()}>
                <UploadComp
                  accept="image/*"
                  title="上传病例照片"
                  beforeUpload={checkImageSize}
                  getUploadToken={getPicToken}
                  value={{}}
                  onChange={(val) => {
                    caseImages.push(val);
                    setCaseImages([...caseImages]);
                  }}
                ></UploadComp>
              </div>
            )}
          </div>
        </div>

        <div style={{ color: "#fff", height: "16px" }}></div>
        <div style={{ color: "#fff", height: "16px" }}></div>

        <div className="assess-section-sep"></div>
        <div className="assess-submit-wrapper">
          <div className="assess-submit-tips">
            1.
            评估表只能提交一次，确认提交后无法修改，请认真填写。评估视频，照片背景应避免杂乱，尽量调试好清晰完整的拍摄角度。
            <br />
            2. 请尽量穿着贴身运动衣服，短裤背心为佳，光脚。
            <br />
            3. 提交后，1-3个工作日完成评估报告（不包含周六周末，法定节假日）
            <br />
            4. 填写时任何紧急问题，请联系客服微信：dankejiankang02
            <br />
            5.
            评估测试中的动作，谨记无痛原则，产生疼痛的测试可以暂时不做，联系客服进行反馈。
            <br />
            6. 评估预约下单后，即进入评估流程，不接受退款。
            <br />
          </div>
          <div className="assess-submit-btn" onClick={onSubmit}>
            提交
          </div>
        </div>
      </div>
      <div
        onClick={onSaveDraft}
        className="assess-save-draft"
        style={{ display: showSaveDraft ? "block" : "none" }}
      >
        保存草稿
      </div>
    </div>
  );
};

export default createDOMForm()(FootAssessPage);
